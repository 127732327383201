import React from "react"
import HomeLayout from "../layout/homeLayout"
import SEO from "../components/seo"

export default function Home() {
  return (
    <HomeLayout>
      <SEO title="404: Not Found" />
      Page Not Found
      {/* <FeaturedGameCategories /> */}
      {/* <div>
        <a href="/gme_cnt2/game/index.html">Play Game now</a>
        <a href="/love.html">Love now</a>
      </div> */}
    </HomeLayout>
  )
}
